import Vue from 'vue'
import App from './App.vue'
import './scss/main.scss'
import VueAnalytics from 'vue-analytics'
import router from './router'
import VueMeta from 'vue-meta'

import jQuery from 'jquery'

Vue.use(VueMeta)

window.$ = window.jQuery = jQuery
global.$ = global.jQuery = jQuery

Vue.config.productionTip = false

Vue.use(VueAnalytics, {
  id: 'UA-139247535-1',
  router
})

new Vue({
  router,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered'))
}).$mount('#app')
