<template>
  <div id="app">
    <router-view  :class="over18 ? '' : 'u-blur'" />
    <div v-if="!over18" class="o-modal_wrapper">
      <div class="o-modal Poleno _ExtraBold" :class="over18 ? '' : 'active'">
        <h1 class="u-pv">SEXUAL CONTENT WARNING</h1>
        <img src="@/assets/over18.jpg" alt="">
        <div class="o-grid u-pv">
          <div class="o-grid__col u-3/6">
            <div class="u-pv" style="color: #ACACAC">
              I AM NOT OVER 18
            </div>
            <a href="https://giphy.com/gifs/gtfo-reaction-chris-hemsworth-gvEtwTYAsV4go/fullscreen"><button class="Poleno _ExtraBold" type="button" name="button">GO AWAY</button></a>
          </div>
          <div class="o-grid__col u-3/6">
            <div class="u-pv">I AM OVER 18</div>
            <button class="Poleno _ExtraBold" type="button" name="button" @click="over18 = true">LET ME IN!</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Read The Thirsty Gay Magazine Online',
    // all titles will be injected into this template
    titleTemplate: 'QUENCH | %s'
  },
  data: () => ({
    over18: false
  }),
  mounted () {
    // do something after mounting vue instance
    if (localStorage.over18) {
      this.over18 = localStorage.over18
    }
  },
  watch: {
    over18 (newName) {
      localStorage.over18 = newName
    }
  },
  methods: {
    acceptOver18 () {
      this.over18 = true
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  max-width: 640px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}
.u-blur {
  filter: blur(15px);
  -webkit-filter: blur(15px);
}
.o-modal {
  position: fixed;
  top: 0px;
  left: 0px;
  max-width: 640px;
  width: 100%;
  background: #000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &_wrapper {
    position: fixed;
    background: rgba(0,0,0,0.8);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
