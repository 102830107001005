import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

import Meta from 'vue-meta'

Vue.use(Router)
Vue.use(Meta)

export default new Router({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 0)
    })
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/thank-you',
      name: 'ThankYou',
      // route level code-splitting
      // this generates a separate chunk (thank-yout.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "thankyou" */ './views/ThankYou.vue')
    },
    {
      path: '/privacy',
      name: 'Privacy',
      // route level code-splitting
      // this generates a separate chunk (thank-yout.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "privacy" */ './views/Privacy.vue')
    },
    {
      path: '/magazine/summer-2019',
      name: 'MagazineSummer2019',
      // route level code-splitting
      // this generates a separate chunk (thank-yout.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "summer" */ './views/Magazine/Summer2019.vue')
    }
  ]
})
