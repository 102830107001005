<template>
  <div id="" class="c-about u-pt-x8 u-pb-x4">
    <div class="c-about-content">
      <!-- <div class="separator bottom" /> -->
      <img src="../assets/adrianshane.png" alt="">
      <div class="u-pl u-pb u-text-left" style="font-size: 12px">
        Credit: Adrian + Shane
      </div>
      <!-- <div class="separator top" /> -->
      <div class="u-text-left u-m u-pt-x4 u-pb-x4">
        <h3>/ABOUT</h3>
        <p>QUENCH is the sex-positive magazine for all the <b>thirsty gays out there</b>, giving them a voice for their stories and issues.</p>
        <p>It’s a space where <b>sex, sexuality and the male body are celebrated unashamedly</b>, where taboo topics get talked about and where gays go to QUENCH that thirst.</p>
        <p class="Poleno _Medium u-font-40"><b>Take a gulp!</b></p>
      </div>
      <a href="https://mailchi.mp/dc3a18d23e63/subscribe-quench-magazine" target="_blank"><button class="u-mt" type="button" name="button">READ QUENCH ONLINE</button></a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ThisIssue',
  data: () => ({

  })
}
</script>
<style lang="scss" scoped>

.c-about {
  &-content {
    img {
      width: 100.1%;
    }
    p {
      font-size: 24px;
      color: #FFFFFF;
      letter-spacing: 0;
      + p {
        padding-top: 1em
      }
    }
  }
  &-text {
    padding: 80px 0 ;
  }
}
.u-font-40 {
  font-size: 40px !important;
}
h3 {
font-size: 15px;
color: #7E7E7E;
font-weight: 800;
}
.o-list {
  font-size: 24px;
  font-weight: 800;
}
.separator {
  position: relative;
  width: 100.1%;
  height: 100px;
  &.reverse {
    transform: rotateY(180deg);
  }
  &.bottom {
    margin-bottom: -100px;
    z-index: 1;
    &::before {
      left: 0;
      position: absolute;
      content: "";
      width: 100.1%;
      height: 100.1%;
      background-color: #282828;
      clip-path: polygon(100.1% 0, 0 0, 0 100.1%);
    }
  }
  &.top {
    margin-top: -100px;
    &::after {
      left: 0;
      position: absolute;
      content: "";
      width: 100.1%;
      height: 100.1%;
      background-color: #282828;
      clip-path: polygon(100.1% 0, 0 100.1%, 100.1% 100.1%);
    }
  }
}
</style>
