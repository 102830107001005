<template>
  <div>
    <Hero />
    <ThisIssue />
    <About />
    <q-footer />
  </div>
</template>

<script>
import Hero from '@/components/Hero.vue'
import ThisIssue from '@/components/ThisIssue.vue'
import About from '@/components/About.vue'
import QFooter from '@/components/QFooter.vue'
export default {
  name: 'Homepage',
  metaInfo: {
    title: 'Read The Thirsty Gay Magazine Online',
    meta: [
      {
        name: 'title',
        content: 'Read The Thirsty Gay Magazine Online'
      },
      {
        name: 'og:title',
        content: 'Read The Thirsty Gay Magazine Online'
      },
      {
        name: 'twitter:title',
        content: 'Read The Thirsty Gay Magazine Online'
      },
      {
        name: 'description',
        content: 'QUENCH is the NSFW, sex-positive, gay magazine for all the thirsty gays out there, giving them a voice for their stories. Take a gulp, QUENCH that thirst!'
      },
      {
        property: 'og:description',
        content: 'QUENCH is the NSFW, sex-positive, gay magazine for all the thirsty gays out there, giving them a voice for their stories. Take a gulp, QUENCH that thirst!'
      },
      {
        property: 'twitter:description',
        content: 'QUENCH is the NSFW, sex-positive, gay magazine for all the thirsty gays out there, giving them a voice for their stories. Take a gulp, QUENCH that thirst!'
      }
    ]
  },
  components: {
    Hero,
    ThisIssue,
    About,
    QFooter
  }
}
</script>

<style lang="scss">
@import url("https://use.typekit.net/peg5set.css");
.Poleno._ExtraBold {
  font-family: poleno, sans-serif;
  font-weight: 700;
  font-style: normal;
}

.Poleno._Medium {
  font-family: poleno, sans-serif;
  font-weight: 400;
  font-style: normal;
}
body {
  background: #282828;
}
#app {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  max-width: 640px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}
</style>
