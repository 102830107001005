<template>
  <div id="hero">
    <div class="c-hero">
      <img alt="Vue logo" class="u-p c-logo" src="@/assets/logo.png">
      <img src="@/assets/bg-top.png">
    </div>
    <div class="c-subtitle">
      <!-- <div class="separator top reverse" /> -->
      <div ref="subtitleBottom" class="c-subtitle-content u-text-left u-ph-x2">
        <p class="Poleno _ExtraBold">The magazine where thirst and thought collide</p>
      </div>
      <!-- <div class="separator bottom" /> -->
    </div>
    <div class="c-subtitle-img">
      <img src="@/assets/bg-btm.png" alt="">
    </div>
  </div>
</template>
<script>
export default {
  name: 'Hero'
}
</script>
<style lang="scss" scoped>
#hero {
}
.c-logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.c-hero {
  min-height: 100vh;
  position: relative;
  // background-image: url('../');
  background-repeat: no-repeat;
  background-size: cover;
  // margin-bottom: -40px;
}
.c-subtitle {
  margin-top: -1px;
  margin-bottom: -1px;
  position: relative;
  z-index: 1;
  font-size: 41px;
  &-content {
    background: #000000;
  }
  &-top {
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }
  &-bottom {
    width: 100%;
    bottom: 0;
    left: 0;
    position: absolute;
  }
  &-img {
    position: relative;
    z-index: -1;
  }

  .separator {
    position: relative;
    width: 100%;
    height: 80px;
    &.reverse {
      transform: rotateY(180deg);
    }
    &.bottom {
      height: 100px;
      margin-bottom: -100px;
      &::before {
        left: 0;
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background-color: #000;
        clip-path: polygon(100% 0, 0 0, 0 100%);
      }
    }
    &.top {
      &::after {
        left: 0;
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background-color: #000;
        clip-path: polygon(100% 0, 0 100%, 100% 100%);
      }
    }
  }
}
</style>
