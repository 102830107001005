<template>
  <div id="footer" >
    <div class="c-footer">
      <div class="o-grid u-pv-x2 u-4/6 u-push-1/6">
        <div class="o-grid__col u-3/9 u-p">
          <a href="https://instagram.com/quench_ie" target="_blank">
            <img src="../assets/instagram.svg" alt="">
          </a>
        </div>
        <div class="o-grid__col u-3/9 u-p">
          <a href="http://facebook.com/quench.irl" target="_blank">
            <img src="../assets/facebook.svg" alt="">
          </a>
        </div>
        <div class="o-grid__col u-3/9 u-p">
          <a href="https://twitter.com/quench_IE" target="_blank">
            <img src="../assets/twitter.svg" alt="">
          </a>
        </div>
      </div>
      <div class="u-text-center">
        <a class="u-color-white" href="mailto:quench.men@gmail.com">Contact QUENCH</a>
      </div>
      <div class="u-pv u-text-center u-jc-center">
        <div class="c-footer__text u-ph u-pv">
          <a class="u-color-white" href="https://drive.google.com/drive/folders/1CGybnjyYaL8zX7i9QBSF7tRklsQta3M4" target="_blank">Press Release</a>
        </div>
        <div class="c-footer__text u-ph u-pv">
          <router-link class="u-color-white" :to="{ name: 'Privacy'}">Cookies & Privacy Policy</router-link>
        </div>
      </div>
      <div class="c-footer__text u-ph u-pv">
        Copyright QUENCH 2019
      </div>
    </div>
    <div class="c-cool u-text-center u-p" style="font-size: 14px">
      designed by <a href="https://twitter.com/M1KEMARK" target="_blank"><b>Mike Mark</b></a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ThisIssue',
  data: () => ({

  })
}
</script>
<style lang="scss" scoped>
.c-footer {
  background: #000;
  &__text {
    font-size: 11px;
    color: #FFFFFF;
  }
}
.c-cool {
  background: #fff;
  color: #000;
  a {
    text-decoration: none;
  }
}
</style>
