<template>
  <div id="" class="c-issue">
    <div class="separator top reverse" />
    <div class="u-pl u-pb u-text-left" style="font-size: 12px">
      Credit: Sports Militia
    </div>
    <div class="c-issue-content u-p">
      <p class="u-mb">Read the first issue online for free.</p>
      <a href="https://mailchi.mp/dc3a18d23e63/subscribe-quench-magazine" target="_blank">
        <button class="u-mt" type="button" name="button">READ QUENCH ONLINE</button>
      </a>
      <div class="u-text-left c-issue-text">
        <h3>/THIS ISSUE</h3>
        <ul class="o-list Poleno _ExtraBold">
          <li class="o-list--item">Sex On PrEP</li>
          <li class="o-list--item">Chemsex Consent</li>
          <li class="o-list--item">Fetish Exploration</li>
          <li class="o-list--item">Sports Militia</li>
          <li class="o-list--item">Femme Form</li>
          <li class="o-list--item">Adrian + Shane</li>
          <li class="o-list--item">Eggplant Soup</li>
          <li class="o-list--item">RIP Tumblr</li>
          <li class="o-list--item">Fully Loaded</li>
        </ul>
      </div>
    </div>
    <p class="u-mb">Read the first issue online for free.</p>
    <a href="https://mailchi.mp/dc3a18d23e63/subscribe-quench-magazine" target="_blank">
      <button class="u-mt" type="button" name="button">READ QUENCH ONLINE</button>
    </a>
    <div class="separator bottom" />
  </div>
</template>
<script>
export default {
  name: 'ThisIssue',
  data: () => ({

  })
}
</script>
<style lang="scss" scoped>
.c-issue {
  &-content {
    background: #282828;
  }
  &-text {
    padding: 80px 0 ;
    .o-list {
      font-size: 30px;
      font-weight: 800;
    }
  }
}
h3 {
font-size: 15px;
color: #7E7E7E;
font-weight: 800;
}

.separator {
  position: relative;
  width: 100%;
  height: 80px;
  &.reverse {
    transform: rotateY(180deg);
  }
  &.bottom {
    margin-bottom: -80px;
    &::before {
      left: 0;
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background-color: #282828;
      clip-path: polygon(100% 0, 0 0, 0 100%);
    }
  }
  &.top {
    margin-top: -80px;
    &::after {
      left: 0;
      top: 0px;
      position: absolute;
      content: "";
      width: 100%;
      height: calc(100% + 1px);
      background-color: #282828;
      clip-path: polygon(100% 0, 0 100%, 100% 100%);
    }
  }
}
</style>
